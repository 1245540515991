import { MaiActionsState } from './maiActionTypes';
import { addNewActions, updateActions, generateNotificationMessage } from './utils';
import actions from '../../pages/ActionsTable/actionsWithKey.json'




export const INIT_STATE: MaiActionsState = {
  actionsHistoryId: 0,
  allActions: {}, //  {actions},
  // tags: {},
  loading: false,
  error: '',
  notificationMessage: ''
};

const maiActionsReducer = (state = INIT_STATE, action: any): MaiActionsState => {
  switch (action.type) {
    case 'ADD_NEW_ACTIONS':
      return addNewActions(state, action.payload);
    case 'API_RESPONSE_SUCCESS':
      // console.log("API_RESPONSE_SUCCESS:", action.payload.data);
      switch (action.payload.actionType) {
        case 'ADD_NEW_ACTIONS':
          console.log("add new actions payload", action.payload.data)
          return {
            ...addNewActions(state, action.payload.data),
            actionsHistoryId: action.payload.data.actionsHistoryId,
            loading: false,
            error: '',
          };
        case 'UPDATE_ACTIONS':
          return {
            ...updateActions(state, action.payload.data),
            actionsHistoryId: action.payload.data.actionsHistoryId,
            loading: false,
            error: '',
          };
        default:
          return { ...state };
      }

    case 'API_RESPONSE_ERROR':
      switch (action.payload.actionType) {
        case 'ADD_NEW_ACTIONS':
        case 'UPDATE_ACTIONS':
          return {
            ...state,
            loading: false,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }

    case 'FETCH_NEW_ACTIONS_REQUEST':
      // console.log("Fetch new actions pre api triggerred")
      return {
        ...state,
        loading: true,
        error: '',
      };

    case 'UPDATE_ACTIONS_FROM_SSE': {

      console.log(" update actions from sse", action.payload)
      const { New, Updated, actionsHistoryId } = action.payload;

      // Handle new actions: add them to the state
      const newActions = New.actions.reduce((acc: any, action: any) => {
        acc[action.action_id] = action;
        return acc;
      }, {});

      // Handle updated actions: update existing actions in the state
      const updatedActions = Updated.actions.reduce((acc: any, action: any) => {
        if (state.allActions[action.action_id]) {
          acc[action.action_id] = {
            ...state.allActions[action.action_id], // preserve existing data
            ...action // update with new data
          };
        }
        return acc;
      }, {});

      // Generate the notification message using the utility function
      const notificationMessage = generateNotificationMessage(New.actions, Updated.actions);

      console.log("Notification message is :", notificationMessage)
      console.log("Actions History id being set is >> ", actionsHistoryId)

      return {
        ...state,
        allActions: {
          ...state.allActions,
          ...newActions, // add new actions
          ...updatedActions, // update existing actions
        },
        notificationMessage: notificationMessage, // Set the message only if valid
        actionsHistoryId: actionsHistoryId
      }
    }

    default:
      return state;
  }
};

export default maiActionsReducer;
