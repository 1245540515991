// actions.ts
import { AddNewActionsPayload, UpdateActionsPayload  } from './maiActionTypes';

export const apiResponseSuccess = (actionType: string, data: any) => ({
  type: 'API_RESPONSE_SUCCESS',
  payload: { actionType, data },
});

export const apiResponseError = (actionType: string, error: string) => ({
  type: 'API_RESPONSE_ERROR',
  payload: { actionType, error },
});

export const fetchNewActionsRequest = (payload: { userId: number, actionsHistoryId: number  }) => ({
  type: 'FETCH_NEW_ACTIONS_REQUEST',
  payload,
});



export const addNewActions = (payload: AddNewActionsPayload) => ({
  type: 'ADD_NEW_ACTIONS',
  payload,
});

export const updateActions = (payload: UpdateActionsPayload) => ({
  type: 'UPDATE_ACTIONS',
  payload,
});

export const getRealTimeSSE = (payload: { userId: number, historyId: number }) => ({
  type: 'GET_REALTIME_SSE',
  payload,
});

export const updateActionsFromSSE = (payload: any) => ({
  type: 'UPDATE_ACTIONS_FROM_SSE',
  payload
});