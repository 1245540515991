// all routes
import Routes from './routes/Routes';

// // helpers
// import { configureFakeBackend } from './helpers';

// For Default import Theme.scss
import './assets/scss/Theme.scss';

const App = () => {
    // configureFakeBackend();
    return <Routes />;
};

export default App;



// import React from 'react';
// import logo from './logo.svg';
// import './App.css';
// import { getNodeBoilerplate } from './api/backend'; // Adjust the path as necessary

// function App() {
//   const handleLogin = async () => {
//     const data = {
//       "Hi Request": "there",
//     };

//     try {
//       const response = await getNodeBoilerplate(data);
//       if (response.error) {
//         console.error('Request failed:', response.message);
//       } else {
//         console.log('Request successful:', response);
//       }
//     } catch (error) {
//       console.error('Unexpected error:', error);
//     }
//   };

//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.tsx</code> and save to reload.
//         </p>
//         <button
//           className="App-link"
//           onClick={handleLogin} // Trigger login on click
//         >
//           Learn React
//         </button>
//       </header>
//     </div>
//   );
// }

// export default App;



