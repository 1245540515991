import ReactDOM from 'react-dom';

import './i18n';
// import '@iconscout/react-unicons/css/line.css'; // Add this line to import Unicons CSS
import "./assets/css/materialdesignicons.min.css";


import App from './App';
import reportWebVitals from './reportWebVitals';    


import { Provider } from 'react-redux';


// import { configureStore } from './redux/store';

// ReactDOM.render(
//     <Provider store={configureStore({})}>
//         <App />
//     </Provider>,
//     document.getElementById('root')
// );



import PersistProvider from './redux/store/persist-provider';
import { store } from './redux/store/index'
// const root = createRoot(container);
ReactDOM.render(
    <Provider store={store}>
        <PersistProvider>
            <App />
        </PersistProvider>
    </Provider>,
    document.getElementById('root')    
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();




// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
